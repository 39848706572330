<section class="dashboard-container h-100">
  <header class="header navbar fixed-top">
    <a class="navbar-brand logo" (click)="goTop()" [routerLink]="'/user/dashboard'" placement="right"
      ngbTooltip="Ultra Fantasy League" container="body">
      <img src="../../assets/img/logo.png" />
    </a>
    <button class="navbar-toggler sidebar-toggler d-none" type="button">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="welcome-text" *ngIf='timedelayforgettingracetime==true'>
      <div class="user-name">Hi <i>{{userName}} ,</i></div>
      <div>
        <span class="team-name">{{teamName}}</span>
        <span class="current-time">{{date_now | date: 'medium'}}</span>
      </div>
    </div>
    <!-- if no race -->
    <div class="welcome-text" *ngIf='noRace==true'>
      <div class="user-name">Hi<i>{{userName}} ,</i></div>
      <div>
        <span class="team-name">{{teamName}}</span>
        <span class="current-time">{{date_now | date: 'medium'}}</span>
      </div>
    </div>

    <ul class="nav navbar-nav ml-auto" *ngIf="timedelayforgettingracetime==true">
      <li class="sub-status mob-hidden" *ngIf="status=='In Active'">
        <span class="label">Payment : &nbsp;</span>
        <!-- <span class="value "> {{'Pending'}}</span> -->
        <button *ngIf="status=='In Active'" (click)="invokeRazorPay($event)" class="btn btn-primary" data-toggle="modal" data-target="#paynowModal">Pay Now</button>
      </li> 
      <li class="nav-item race-entry-time" *ngIf="entryClosed==false">
        <div class="sub-status desktop-hidden" *ngIf="status=='In Active'">
          <span class="label">Payment : &nbsp;</span>
          <!-- <span class="value"> {{'Pending'}}</span> -->
          <button *ngIf="status=='In Active'" (click)="invokeRazorPay($event)" class="btn btn-primary" data-toggle="modal" data-target="#paynowModal">Pay Now</button>
        </div>
        <div class="race-name">
          <a>Race entry for <span class="d-block">{{raceName}}</span></a>
        </div>
        <div class="time-list" [style.color]="isTimeRed ==true ? 'red' : 'white'">
          <ul>
            <li><span class="number">{{_days}}</span><span class="text">Days</span></li>
            <li><span class="number">{{_hours}}</span><span class="text">Hrs</span></li>
            <li><span class="number">{{_minutes}}</span><span class="text">Mins</span></li>
            <li><span class="number">{{_seconds}}</span><span class="text">Secs</span></li>
          </ul>
        </div>
      </li>
      <li class="nav-item race-entry-time" *ngIf="entryClosed==true">
        <div class="race-name">
          <a>Race entry closed for <span class="d-block">{{raceName}}</span></a>
        </div>
      </li>
      <li class="nav-item user-profile">
        <a (click)="goTop();navFromProfile()" [routerLink]="'/user/profile'" class="nav-link dp-img" placement="bottom"
          ngbTooltip="Profile" container="body" [ngClass]="{'active': router.url == '/user/profile'}"
          [ngStyle]="{backgroundImage: 'url(' +this.userImage+ ')'}">
          <span>
            <!-- <i class="fa fa-user"></i> -->
            <!-- <img [src]="'data:image/jpg;base64,'+this.userImage" title="Profile"> -->
          </span>
        </a>
      </li>
    </ul>


    <ul class="nav navbar-nav ml-auto" *ngIf='noRace==true'>
      <li class="nav-item race-entry-time">
        <div class="race-name">
          <a>No races available</a>
        </div>
      </li>

      <li class="nav-item user-profile">
        <a (click)="goTop();navFromProfile()" [routerLink]="'/user/profile'" class="nav-link dp-img" placement="bottom"
          ngbTooltip="Profile" container="body" [ngClass]="{'active': router.url == '/user/profile'}"
          [ngStyle]="{backgroundImage: 'url(' +this.userImage+ ')'}">
        </a>
      </li>
    </ul>
    <!-- Hamburger Menu -->
    <div class="bars d-block d-lg-none" (click)="showMobileMenu=true">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" width="30" height="30" focusable="false">
        <title>Menu</title>
        <path stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-miterlimit="10"
          d="M4 7h22M4 15h22M4 23h22"></path>
      </svg>
    </div>
    <!-- <marquee class="morquee-scroll" [ngClass]="{'with-pay-btn': status=='In Active', 'without-pay-btn': status!='In Active'  }"  behavior="scroll" direction="left" onmouseover="this.stop();"
      onmouseout="this.start();">For
        <button type="button" class="btn btn-primary">Save changes</button>mula 1, FIA and AGPC has cancelled the 2020
      Australian Grand Prix in an effort to limit the rapid spread of Corona virus pandemic. <a
        href="https://www.formula1.com/en/latest/article.formula-1-fia-and-agpc-announce-cancellation-of-the-2020-australian-grand.KKpXZDcd77WbO6T0MGoO7.html"
        target="_blank">Read More...</a></marquee> -->

  </header>

<!-- Modal -->
<!-- <div class="modal fade" id="paynowModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document" style="max-width: 380px;">
    <div class="modal-content">
     
      <div class="modal-body" style="margin: 0px auto;max-width: 350px;display: block;">
        <img  src="../../../../assets/img/UltraF1QRCode.jpeg" class="img-fluid" />

      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" data-dismiss="modal">Close </button>
      </div>
    </div>
  </div>P
</div> -->

  <!-- Sidebar -->
  <div class="sidebar" [class.open]="showMobileMenu" (click)="showMobileMenu=false">
    <div class="menu">
      <ul class="list-unstyled">
        <li><a (click)="goTop()" [routerLink]="'/user/dashboard'" class="active" id="pageActive"
            [ngClass]="{'active': router.url == '/user/dashboard'}" placement="right" ngbTooltip="Dashboard"
            container="body"><span class="have-icon">
              <svg xmlns="http://www.w3.org/2000/svg" width="43.043" height="43.043" viewBox="0 0 43.043 43.043">
                <g class="a">
                  <path class="b"
                    d="M21.522,0A21.522,21.522,0,1,0,43.043,21.522,21.568,21.568,0,0,0,21.522,0Zm0,40.521a19,19,0,1,1,19-19A19.021,19.021,0,0,1,21.522,40.521Zm0,0" />
                  <path class="b"
                    d="M77.393,61A16.393,16.393,0,1,0,93.787,77.393,16.412,16.412,0,0,0,77.393,61ZM90,78.653h1.2a13.766,13.766,0,0,1-1.221,4.554l-8.874-5.123a3.741,3.741,0,0,0,0-1.382l8.874-5.123a13.764,13.764,0,0,1,1.221,4.553H90a1.261,1.261,0,1,0,0,2.521Zm-12.61-2.521a1.261,1.261,0,1,1-1.261,1.261A1.263,1.263,0,0,1,77.393,76.132ZM69.364,66.088l.653,1.05a1.261,1.261,0,1,0,2.142-1.332l-.615-.989a13.768,13.768,0,0,1,4.588-1.238v1.2a1.261,1.261,0,0,0,2.522,0v-1.2A13.764,13.764,0,0,1,83.207,64.8l-.6,1.041A1.261,1.261,0,0,0,84.79,67.1l.6-1.038A13.994,13.994,0,0,1,88.722,69.4l-8.873,5.122a3.781,3.781,0,0,0-1.195-.692v-4a1.261,1.261,0,1,0-2.522,0v4a3.782,3.782,0,1,0,3.717,6.44l8.873,5.122a13.986,13.986,0,0,1-1.548,1.831l-3.935-3.235a1.261,1.261,0,0,0-.8-.287H72.349a1.261,1.261,0,0,0-.8.287l-3.935,3.235a13.987,13.987,0,0,1-1.548-1.831l1.038-.6a1.261,1.261,0,0,0-1.261-2.184l-1.041.6a13.767,13.767,0,0,1-1.221-4.553h1.2a1.261,1.261,0,0,0,0-2.522h-1.2a13.77,13.77,0,0,1,1.206-4.521l1.088.587a1.261,1.261,0,0,0,1.2-2.22l-1.026-.553a13.983,13.983,0,0,1,3.318-3.337Zm.229,22.77L72.8,86.221h9.185l3.208,2.637a13.843,13.843,0,0,1-15.6,0Zm0,0"
                    transform="translate(-55.872 -55.872)" />
                </g>
              </svg></span></a></li>
        <li><a (click)="goTop()" [routerLink]="'/user/raceEntry'"
            [ngClass]="{'active': router.url == '/user/raceEntry'}" placement="right" ngbTooltip="Race Entry"
            container="body"><span class="have-icon">

              <svg xmlns="http://www.w3.org/2000/svg" width="38.359" height="38.359" viewBox="0 0 38.359 38.359">
                <g class="a">
                  <path class="b" d="M182.124,214.248h4.5a1.124,1.124,0,1,0,0-2.248h-4.5a1.124,1.124,0,1,0,0,2.248Zm0,0"
                    transform="translate(-167.439 -196.117)" />
                  <path class="b" d="M186.619,272h-4.5a1.124,1.124,0,0,0,0,2.248h4.5a1.124,1.124,0,0,0,0-2.248Zm0,0"
                    transform="translate(-167.439 -251.622)" />
                  <path class="b" d="M186.619,332h-4.5a1.124,1.124,0,0,0,0,2.248h4.5a1.124,1.124,0,0,0,0-2.248Zm0,0"
                    transform="translate(-167.439 -307.126)" />
                  <path class="b"
                    d="M123.248,213.124A1.124,1.124,0,1,1,122.124,212,1.124,1.124,0,0,1,123.248,213.124Zm0,0"
                    transform="translate(-111.935 -196.117)" />
                  <path class="b"
                    d="M123.248,273.124A1.124,1.124,0,1,1,122.124,272,1.124,1.124,0,0,1,123.248,273.124Zm0,0"
                    transform="translate(-111.935 -251.622)" />
                  <path class="b"
                    d="M123.248,333.124A1.124,1.124,0,1,1,122.124,332,1.124,1.124,0,0,1,123.248,333.124Zm0,0"
                    transform="translate(-111.935 -307.126)" />
                  <path class="b"
                    d="M37.236,22.626H29.294V7.942A3.375,3.375,0,0,0,25.923,4.57H22.551v-1.2a1.124,1.124,0,0,0-1.124-1.124H19.562A5.932,5.932,0,0,0,14.684,0,5.932,5.932,0,0,0,9.806,2.248H7.942A1.124,1.124,0,0,0,6.818,3.371v1.2H3.371A3.375,3.375,0,0,0,0,7.942V34.988a3.375,3.375,0,0,0,3.371,3.371H37.236a1.124,1.124,0,0,0,1.124-1.124V23.75A1.124,1.124,0,0,0,37.236,22.626ZM9.065,4.5h1.38a1.123,1.123,0,0,0,1.016-.645,3.552,3.552,0,0,1,3.223-1.6,3.552,3.552,0,0,1,3.223,1.6,1.123,1.123,0,0,0,1.016.645H20.3V6.818H9.065ZM2.248,34.988V7.942A1.125,1.125,0,0,1,3.371,6.818H6.818V7.942A1.124,1.124,0,0,0,7.942,9.065H21.427a1.124,1.124,0,0,0,1.124-1.124V6.818h3.371a1.125,1.125,0,0,1,1.124,1.124V22.626H24.8V12.512a1.124,1.124,0,0,0-1.124-1.124H5.619A1.124,1.124,0,0,0,4.5,12.512V32.74a1.124,1.124,0,0,0,1.124,1.124H22.551v2.248H3.371A1.125,1.125,0,0,1,2.248,34.988Zm4.5-3.371V13.636H22.551V27.779l-3.837,3.837Zm29.369,4.5H24.8V32.74a1.124,1.124,0,0,0-1.124-1.124H21.893l2.577-2.577a1.133,1.133,0,0,0,.329-.795V24.874H36.112Zm0,0" />
                  <path class="b"
                    d="M361.33,364.574a1.123,1.123,0,0,0,0,1.589l2.248,2.248a1.124,1.124,0,0,0,1.8-.292l2.248-4.5a1.124,1.124,0,1,0-2.01-1.005l-1.551,3.1-1.145-1.145A1.123,1.123,0,0,0,361.33,364.574Zm0,0"
                    transform="translate(-333.954 -334.876)" />
                </g>
              </svg>
            </span></a></li>

            <!-- <li><a (click)="goTop()" [routerLink]="'/user/raceSprintEntry'"
              [ngClass]="{'active': router.url == '/user/raceSprintEntry'}" placement="right" ngbTooltip="Race Sprint Entry"
              container="body"><span class="have-icon">

                <svg xmlns="http://www.w3.org/2000/svg" width="38.359" height="38.359" viewBox="0 0 38.359 38.359">
                  <g class="a">
                    <path class="b" d="M182.124,214.248h4.5a1.124,1.124,0,1,0,0-2.248h-4.5a1.124,1.124,0,1,0,0,2.248Zm0,0"
                      transform="translate(-167.439 -196.117)" />
                    <path class="b" d="M186.619,272h-4.5a1.124,1.124,0,0,0,0,2.248h4.5a1.124,1.124,0,0,0,0-2.248Zm0,0"
                      transform="translate(-167.439 -251.622)" />
                    <path class="b" d="M186.619,332h-4.5a1.124,1.124,0,0,0,0,2.248h4.5a1.124,1.124,0,0,0,0-2.248Zm0,0"
                      transform="translate(-167.439 -307.126)" />
                    <path class="b"
                      d="M123.248,213.124A1.124,1.124,0,1,1,122.124,212,1.124,1.124,0,0,1,123.248,213.124Zm0,0"
                      transform="translate(-111.935 -196.117)" />
                    <path class="b"
                      d="M123.248,273.124A1.124,1.124,0,1,1,122.124,272,1.124,1.124,0,0,1,123.248,273.124Zm0,0"
                      transform="translate(-111.935 -251.622)" />
                    <path class="b"
                      d="M123.248,333.124A1.124,1.124,0,1,1,122.124,332,1.124,1.124,0,0,1,123.248,333.124Zm0,0"
                      transform="translate(-111.935 -307.126)" />
                    <path class="b"
                      d="M37.236,22.626H29.294V7.942A3.375,3.375,0,0,0,25.923,4.57H22.551v-1.2a1.124,1.124,0,0,0-1.124-1.124H19.562A5.932,5.932,0,0,0,14.684,0,5.932,5.932,0,0,0,9.806,2.248H7.942A1.124,1.124,0,0,0,6.818,3.371v1.2H3.371A3.375,3.375,0,0,0,0,7.942V34.988a3.375,3.375,0,0,0,3.371,3.371H37.236a1.124,1.124,0,0,0,1.124-1.124V23.75A1.124,1.124,0,0,0,37.236,22.626ZM9.065,4.5h1.38a1.123,1.123,0,0,0,1.016-.645,3.552,3.552,0,0,1,3.223-1.6,3.552,3.552,0,0,1,3.223,1.6,1.123,1.123,0,0,0,1.016.645H20.3V6.818H9.065ZM2.248,34.988V7.942A1.125,1.125,0,0,1,3.371,6.818H6.818V7.942A1.124,1.124,0,0,0,7.942,9.065H21.427a1.124,1.124,0,0,0,1.124-1.124V6.818h3.371a1.125,1.125,0,0,1,1.124,1.124V22.626H24.8V12.512a1.124,1.124,0,0,0-1.124-1.124H5.619A1.124,1.124,0,0,0,4.5,12.512V32.74a1.124,1.124,0,0,0,1.124,1.124H22.551v2.248H3.371A1.125,1.125,0,0,1,2.248,34.988Zm4.5-3.371V13.636H22.551V27.779l-3.837,3.837Zm29.369,4.5H24.8V32.74a1.124,1.124,0,0,0-1.124-1.124H21.893l2.577-2.577a1.133,1.133,0,0,0,.329-.795V24.874H36.112Zm0,0" />
                    <path class="b"
                      d="M361.33,364.574a1.123,1.123,0,0,0,0,1.589l2.248,2.248a1.124,1.124,0,0,0,1.8-.292l2.248-4.5a1.124,1.124,0,1,0-2.01-1.005l-1.551,3.1-1.145-1.145A1.123,1.123,0,0,0,361.33,364.574Zm0,0"
                      transform="translate(-333.954 -334.876)" />
                  </g>
                </svg>
              </span></a></li> -->
          
        <li><a (click)="goTop()" [routerLink]="'/user/playerladder'"
            [ngClass]="{'active': router.url == '/user/playerladder'}" placement="right" ngbTooltip="Ladder Challenges"
            container="body">
            <span class="have-icon">
              <svg xmlns="http://www.w3.org/2000/svg" width="37.994" height="37.993" viewBox="0 0 37.994 37.993">
                <g transform="translate(0 0)">
                  <path class="a"
                    d="M158.792,242a7.792,7.792,0,1,0,7.792,7.792A7.8,7.8,0,0,0,158.792,242Zm-5.565,7.792a5.541,5.541,0,0,1,.274-1.721l2.007,1.124a3.3,3.3,0,0,0,0,1.194l-2.007,1.124A5.541,5.541,0,0,1,153.226,249.792Zm5.565,1.113a1.113,1.113,0,1,1,1.113-1.113A1.114,1.114,0,0,1,158.792,250.9Zm3.285-1.71,2.007-1.124a5.547,5.547,0,0,1,0,3.441l-2.007-1.124a3.3,3.3,0,0,0,0-1.194Zm.906-3.058-2.014,1.127a3.343,3.343,0,0,0-1.064-.619v-2.306A5.568,5.568,0,0,1,162.983,246.136Zm-5.3-1.8v2.306a3.344,3.344,0,0,0-1.064.619l-2.014-1.127A5.568,5.568,0,0,1,157.679,244.338Zm-3.078,9.109,2.014-1.127a3.343,3.343,0,0,0,1.064.619v2.306a5.568,5.568,0,0,1-3.078-1.8Zm5.3,1.8V252.94a3.344,3.344,0,0,0,1.064-.619l2.014,1.127A5.568,5.568,0,0,1,159.9,255.245Zm0,0"
                    transform="translate(-139.795 -224.042)" />
                  <path class="a"
                    d="M8.35,19.709a12.244,12.244,0,1,0,21.294,0l8.25-18.135A1.113,1.113,0,0,0,36.881,0H25.676A1.113,1.113,0,0,0,24.66.657L19,13.254,13.334.657A1.113,1.113,0,0,0,12.319,0H1.114A1.113,1.113,0,0,0,.1,1.574ZM19,35.767A10.018,10.018,0,1,1,29.015,25.75,10.029,10.029,0,0,1,19,35.767Zm9.15-18.144a12.379,12.379,0,0,0-1.4-1.343L33.145,2.226h2.007ZM26.4,2.226h4.3L24.88,15.015a12.156,12.156,0,0,0-3.648-1.3ZM16.762,13.711a12.155,12.155,0,0,0-3.648,1.3L7.3,2.226h4.3ZM4.849,2.226l6.394,14.054a12.378,12.378,0,0,0-1.4,1.343l-7-15.4Zm0,0"
                    transform="translate(0)" />
                </g>
              </svg>
            </span></a></li>
        <li><a (click)="goTop()" [routerLink]="'/user/racePrediction'"
            [ngClass]="{'active': router.url == '/user/racePrediction'}" placement="right" ngbTooltip="Race Predictions"
            container="body"><span class="have-icon">
              <svg xmlns="http://www.w3.org/2000/svg" width="37.994" height="35.131" viewBox="0 0 37.994 35.131">
                <g transform="translate(-146 -862)">
                  <path
                    d="M8.59,21C7.17,21,6,22.642,6,24.634V52.5c0,1.992,1.17,3.634,2.59,3.634H41.4c1.42,0,2.59-1.642,2.59-3.634V24.634c0-1.992-1.17-3.634-2.59-3.634Zm0,2.423H41.4c.493,0,.863.519.863,1.211v3.029H7.727V24.634C7.727,23.942,8.1,23.423,8.59,23.423Zm-.863,6.663h34.54v4.846H7.727Zm0,7.268h34.54V52.5c0,.692-.37,1.211-.863,1.211H8.59c-.493,0-.863-.519-.863-1.211Zm14.6,3.634c-.477.031-.845.6-.823,1.268s.427,1.186.9,1.155H38.813c.477,0,.864-.542.864-1.211s-.387-1.212-.864-1.212H22.406A.616.616,0,0,0,22.325,40.988Zm0,6.057c-.477.031-.845.6-.823,1.268s.427,1.186.9,1.155H34.5c.477,0,.864-.542.864-1.211s-.387-1.212-.864-1.212H22.406A.616.616,0,0,0,22.325,47.045Z"
                    transform="translate(140 841)" fill="#fff" />
                </g>
              </svg>
            </span></a></li>
        <li><a (click)="goTop()" [routerLink]="'/user/rulesAndRegulation'"
            [ngClass]="{'active': router.url == '/user/rulesAndRegulation'}" placement="right"
            ngbTooltip="Rules and Regulations" container="body"><span class="have-icon">
              <svg xmlns="http://www.w3.org/2000/svg" width="43.965" height="41" viewBox="0 0 43.965 41">
                <g id="noun_rules_1219367" transform="translate(-9.11 -11.87)">
                  <path id="Path_13236" data-name="Path 13236"
                    d="M47.572,11.87H20.687a5.506,5.506,0,0,0-5.5,5.415V41.869H14.3a1.075,1.075,0,0,0-.237.027,5.5,5.5,0,0,0,0,10.948,1.075,1.075,0,0,0,.237.027H38.716a5.512,5.512,0,0,0,5.506-5.5v-24.5h3.35a5.5,5.5,0,1,0,0-11.007ZM11.266,47.359a3.355,3.355,0,0,1,3.35-3.35h19.75a5.463,5.463,0,0,0,0,6.705H14.622a3.355,3.355,0,0,1-3.355-3.339Zm30.8,0a3.35,3.35,0,1,1-3.35-3.35,1.075,1.075,0,1,0,0-2.151H17.337V17.371a3.355,3.355,0,0,1,3.35-3.35h22.53a5.468,5.468,0,0,0-1.145,3.264s0,.027,0,.043Zm5.506-26.633h-3.35V17.371a3.35,3.35,0,1,1,3.35,3.35Z"
                    transform="translate(0 0)" fill="#535666" />
                  <rect id="Rectangle_244" data-name="Rectangle 244" width="3.226" height="2.151"
                    transform="translate(21 35.706)" fill="#535666" />
                  <rect id="Rectangle_245" data-name="Rectangle 245" width="10.754" height="2.151"
                    transform="translate(27.51 35.706)" fill="#535666" />
                  <rect id="Rectangle_246" data-name="Rectangle 246" width="3.226" height="2.151"
                    transform="translate(21 30.094)" fill="#535666" />
                  <rect id="Rectangle_247" data-name="Rectangle 247" width="10.754" height="2.151"
                    transform="translate(27.51 30.094)" fill="#535666" />
                  <rect id="Rectangle_248" data-name="Rectangle 248" width="3.226" height="2.151"
                    transform="translate(21 18.87)" fill="#535666" />
                  <rect id="Rectangle_251" data-name="Rectangle 251" width="3.226" height="2.151"
                    transform="translate(21 24.481)" fill="#535666" />
                  <rect id="Rectangle_249" data-name="Rectangle 249" width="10.754" height="2.151"
                    transform="translate(27.51 18.87)" fill="#535666" />
                  <rect id="Rectangle_250" data-name="Rectangle 250" width="10.754" height="2.151"
                    transform="translate(27.51 24.483)" fill="#535666" />
                </g>
              </svg>
            </span></a></li>
        <!-- <li><a [routerLink]="'/user/userDetails'" [ngClass]="{'active': router.url == '/user/userDetails'}"
              data-toggle="tooltip" data-placement="right" title="User"><span class="have-icon">
                <i class="fa fa-users"></i>
              </span></a></li> -->

        <li class="logout"><a (click)="logout();timeup=false;" placement="right" ngbTooltip="Logout"
            container="body"><span class="have-icon">
              <svg xmlns="http://www.w3.org/2000/svg" width="37.5" height="27.215" viewBox="0 0 37.5 27.215">
                <g id="logout-sign" transform="translate(0)" opacity="0.73">
                  <g id="Group_294" data-name="Group 294" transform="translate(0 0)">
                    <path id="Path_13078" data-name="Path 13078"
                      d="M5.189,134.29H25.276a1.52,1.52,0,1,0,0-3.039H5.19l2.929-2.928a1.52,1.52,0,0,0-2.151-2.149L.454,131.687a1.521,1.521,0,0,0-.342.511,1.537,1.537,0,0,0-.112.572,1.521,1.521,0,0,0,.116.581,1.464,1.464,0,0,0,.329.493l5.521,5.523a1.521,1.521,0,0,0,2.151,0,1.527,1.527,0,0,0,0-2.149Z"
                      transform="translate(0 -119.166)" fill="#fff" />
                    <path id="Path_13079" data-name="Path 13079"
                      d="M132.643,55.239H115.557a5.07,5.07,0,0,0-5.063,5.065l0,4.5a1.515,1.515,0,0,0,2.738.864l.3,0V60.3a2.027,2.027,0,0,1,2.024-2.025h17.086a2.027,2.027,0,0,1,2.024,2.025V77.389a2.027,2.027,0,0,1-2.024,2.025H115.557a2.027,2.027,0,0,1-2.024-2.025l0-5.373h-.3a1.507,1.507,0,0,0-1.22-.612,1.524,1.524,0,0,0-1.52,1.52v4.465a5.07,5.07,0,0,0,5.063,5.065h17.086a5.07,5.07,0,0,0,5.064-5.065V60.3A5.07,5.07,0,0,0,132.643,55.239Z"
                      transform="translate(-100.206 -55.239)" fill="#fff" />
                  </g>
                </g>
              </svg>

            </span></a></li>


      </ul>
    </div>
  </div>

  <!-- Dashboard -->
  <div class="box-loader" *ngIf="preLoader">
    <img class="text-center image-responsive-height demo-mw-50" src="assets/img/progress.svg" alt="Progress">
  </div>
  <!-- Overlay -->
  <div class="overlay" [class.open]="showMobileMenu" (click)="showMobileMenu=false">

  </div>

  <router-outlet></router-outlet>
</section>
